import { History } from './history';
import { Message } from '../message/message';

export class Conversation {
    id: string;
    // tslint:disable-next-line:variable-name
    date_created: string;
    // tslint:disable-next-line:variable-name
    customer_endpoint: string;
    status: string;
    // tslint:disable-next-line:variable-name
    account_id: string;
    // tslint:disable-next-line:variable-name
    date_edited: string;
    customer: string;
    // tslint:disable-next-line:variable-name
    provider_id: string;
    // tslint:disable-next-line:variable-name
    customer_id: string;
    disposition: number;
    // tslint:disable-next-line:variable-name
    customer_name: string;
    // tslint:disable-next-line:variable-name
    customer_email: string;
    // tslint:disable-next-line:variable-name
    customer_address: string;
    // tslint:disable-next-line:variable-name
    customer_newinfo: string;
    notes: string;
    messages: Message[];
    history: History[];


    constructor(values: object = {}) {
        this.messages = new Array<Message>();
        this.history = new Array<History>();
        Object.assign(this, values);
    }
}
