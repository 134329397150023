import { Conversation } from "./../conversation/conversation";
import { Binary } from "@angular/compiler";
export class Lead {
  id: string;
  // tslint:disable-next-line:variable-name
  first_name: string;
  // tslint:disable-next-line:variable-name
  last_name: string;
  // tslint:disable-next-line:variable-name
  campaign_id: string;
  active: boolean;
  score: number;
  // tslint:disable-next-line:variable-name
  assigned_user_id: string;
  disposition: string;
  attention: boolean;
  expected_close_date: Date;
  probability: number;
  // tslint:disable-next-line:variable-name
  date_created: Date;
  scheduled: Date;
  phone_number: string;
  email:string;
  lastCallDate:string;
  conversation: Conversation;
  contactPhone: ContactNumber;
  contactEmail: ContactEmail;
  contactAddress: ContactAddress;

  constructor(values: object = {}) {
    this.conversation = new Conversation();
    Object.assign(this, values);
  }
}

export class ContactNumber {
  id: string;
  phonenumber: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}

export class ContactEmail {
  id: string;
  email: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}

export class ContactAddress {
  id: string;
  street: string;
  city: string;
  state: string;
  zip: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}

export class CloseData {
  disposition: string;
  reason: string;
  scheduled: Date;
  product: string;
  attribute1: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
